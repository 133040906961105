
.bmw-0 {
  max-width: none !important;
}

.bmw-1 {
  max-width: 600px * 1.15 !important;
}

.bmw-2 {
  max-width: 600px * 1.3 !important;
}

.bmw-3 {
  max-width: 600px * 1.5 !important;
}

.bmw-4 {
  max-width: 600px * 1.8 !important;
}

.bmw-5 {
  max-width: 600px * 2 !important;
}

.bmw-6 {
  max-width: 600px * 2.2 !important;
}

.bmw-7 {
  max-width: 600px * 2.4 !important;
}

.bmw-8 {
  max-width: 600px * 2.6 !important;
}

.bmw-9 {
  max-width: 600px * 2.8 !important;
}
