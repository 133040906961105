





































.universal-link {
  color: inherit;

  &.no-pointer {
    cursor: default !important;
  }
}

