@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

// Block Spacing (margin).
// .bs-m{direction}

.bs-ma {
  margin: $bs-t $bs-x $bs-b $bs-x;
}

.bs-mx {
  margin-left: $bs-x;
  margin-right: $bs-x;
}

.bs-my {
  margin-top: $bs-t;
  margin-bottom: $bs-b;
}

.bs-mt {
  margin-top: $bs-t;
}

.bs-mr {
  margin-right: $bs-x;
}

.bs-mb {
  margin-bottom: $bs-b;
}

.bs-ml {
  margin-left: $bs-x;
}

@media (max-width: $breakpoint-xs-max) {

  .bs-ma {
    margin: $bs-xs-t $bs-xs-x $bs-xs-b $bs-xs-x;
  }

  .bs-mx {
    margin-left: $bs-xs-x;
    margin-right: $bs-xs-x;
  }

  .bs-my {
    margin-top: $bs-xs-t;
    margin-bottom: $bs-xs-b;
  }

  .bs-mt {
    margin-top: $bs-xs-t;
  }

  .bs-mr {
    margin-right: $bs-xs-x;
  }

  .bs-mb {
    margin-bottom: $bs-xs-b;
  }

  .bs-ml {
    margin-left: $bs-xs-x;
  }
}

// Block Spacing (padding).
// .bs-p{direction}

.bs-pa {
  padding: $bs-t $bs-x $bs-b $bs-x;
}

.bs-px {
  padding-left: $bs-x;
  padding-right: $bs-x;
}

.bs-py {
  padding-top: $bs-t;
  padding-bottom: $bs-b;
}

.bs-pt {
  padding-top: $bs-t;
}

.bs-pr {
  padding-right: $bs-x;
}

.bs-pb {
  padding-bottom: $bs-b;
}

.bs-pl {
  padding-left: $bs-x;
}

@media (max-width: $breakpoint-xs-max) {

  .bs-pa {
    padding: $bs-xs-t $bs-xs-x $bs-xs-b $bs-xs-x;
  }

  .bs-px {
    padding-left: $bs-xs-x;
    padding-right: $bs-xs-x;
  }

  .bs-py {
    padding-top: $bs-xs-t;
    padding-bottom: $bs-xs-b;
  }

  .bs-pt {
    padding-top: $bs-xs-t;
  }

  .bs-pr {
    padding-right: $bs-xs-x;
  }

  .bs-pb {
    padding-bottom: $bs-xs-b;
  }

  .bs-pl {
    padding-left: $bs-xs-x;
  }
}

// Symmetric Block Spacing (margin).
// .sbs-m{direction}

.sbs-ma {
  margin: $sbs-y $sbs-x;
}

.sbs-mx {
  margin-left: $sbs-x;
  margin-right: $sbs-x;
}

.sbs-my {
  margin-top: $sbs-y;
  margin-bottom: $sbs-y;
}

.sbs-mt {
  margin-top: $sbs-y;
}

.sbs-mr {
  margin-right: $sbs-x;
}

.sbs-mb {
  margin-bottom: $sbs-y;
}

.sbs-ml {
  margin-left: $sbs-x;
}

@media (max-width: $breakpoint-xs-max) {

  .sbs-ma {
    margin: $sbs-xs-y $sbs-xs-x;
  }

  .sbs-mx {
    margin-left: $sbs-xs-x;
    margin-right: $sbs-xs-x;
  }

  .sbs-my {
    margin-top: $sbs-xs-y;
    margin-bottom: $sbs-xs-y;
  }

  .sbs-mt {
    margin-top: $sbs-xs-y;
  }

  .sbs-mr {
    margin-right: $sbs-xs-x;
  }

  .sbs-mb {
    margin-bottom: $sbs-xs-y;
  }

  .sbs-ml {
    margin-left: $sbs-xs-x;
  }
}

// Symmetric Block Spacing (padding).
// .sbs-p{direction}

.sbs-pa {
  padding: $sbs-y $sbs-x;
}

.sbs-px {
  padding-left: $sbs-x;
  padding-right: $sbs-x;
}

.sbs-py {
  padding-top: $sbs-y;
  padding-bottom: $sbs-y;
}

.sbs-pt {
  padding-top: $sbs-y;
}

.sbs-pr {
  padding-right: $sbs-x;
}

.sbs-pb {
  padding-bottom: $sbs-y;
}

.sbs-pl {
  padding-left: $sbs-x;
}

@media (max-width: $breakpoint-xs-max) {

  .sbs-pa {
    padding: $sbs-xs-y $sbs-xs-x;
  }

  .sbs-px {
    padding-left: $sbs-xs-x;
    padding-right: $sbs-xs-x;
  }

  .sbs-py {
    padding-top: $sbs-xs-y;
    padding-bottom: $sbs-xs-y;
  }

  .sbs-pt {
    padding-top: $sbs-xs-y;
  }

  .sbs-pr {
    padding-right: $sbs-xs-x;
  }

  .sbs-pb {
    padding-bottom: $sbs-xs-y;
  }

  .sbs-pl {
    padding-left: $sbs-xs-x;
  }
}

// Inner Block Spacing (margin).
// .ibs-m{direction}

.ibs-ma {
  margin: $ibs-y $ibs-x;
}

.ibs-mx {
  margin-left: $ibs-x;
  margin-right: $ibs-x;
}

.ibs-my {
  margin-top: $ibs-y;
  margin-bottom: $ibs-y;
}

.ibs-mt {
  margin-top: $ibs-y;
}

.ibs-mr {
  margin-right: $ibs-x;
}

.ibs-mb {
  margin-bottom: $ibs-y;
}

.ibs-ml {
  margin-left: $ibs-x;
}

@media (max-width: $breakpoint-xs-max) {

  .ibs-ma {
    margin: $ibs-xs-y $ibs-xs-x;
  }

  .ibs-mx {
    margin-left: $ibs-xs-x;
    margin-right: $ibs-xs-x;
  }

  .ibs-my {
    margin-top: $ibs-xs-y;
    margin-bottom: $ibs-xs-y;
  }

  .ibs-mt {
    margin-top: $ibs-xs-y;
  }

  .ibs-mr {
    margin-right: $ibs-xs-x;
  }

  .ibs-mb {
    margin-bottom: $ibs-xs-y;
  }

  .ibs-ml {
    margin-left: $ibs-xs-x;
  }
}

// Inner Block Spacing (padding).
// .ibs-p{direction}

.ibs-pa {
  padding: $ibs-y $ibs-x;
}

.ibs-px {
  padding-left: $ibs-x;
  padding-right: $ibs-x;
}

.ibs-py {
  padding-top: $ibs-y;
  padding-bottom: $ibs-y;
}

.ibs-pt {
  padding-top: $ibs-y;
}

.ibs-pr {
  padding-right: $ibs-x;
}

.ibs-pb {
  padding-bottom: $ibs-y;
}

.ibs-pl {
  padding-left: $ibs-x;
}

@media (max-width: $breakpoint-xs-max) {

  .ibs-pa {
    padding: $ibs-xs-y $ibs-xs-x;
  }

  .ibs-px {
    padding-left: $ibs-xs-x;
    padding-right: $ibs-xs-x;
  }

  .ibs-py {
    padding-top: $ibs-xs-y;
    padding-bottom: $ibs-xs-y;
  }

  .ibs-pt {
    padding-top: $ibs-xs-y;
  }

  .ibs-pr {
    padding-right: $ibs-xs-x;
  }

  .ibs-pb {
    padding-bottom: $ibs-xs-y;
  }

  .ibs-pl {
    padding-left: $ibs-xs-x;
  }
}
