
.swiper-container, .swiper-wrapper {
  z-index: auto;
  align-items: stretch;
}

.swiper-button-prev, .swiper-button-next {
  z-index: auto;
}

.swiper-container .swiper-slide {
  height: auto;
}
