@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.sc-chat-window {
  border-radius: .4rem;
  width: 100% !important;
  background-color: transparent !important;

  .sc-message-list {
    background-color: rgba(150, 150, 150, .2) !important;
  }

  .sc-user-input {
    background-color: rgba(150, 150, 150, .2) !important;
  }

  .sc-user-input--text {
    width: 100%;
    color: white !important;
  }

  .sc-user-input--file-icon-wrapper {
    display: flex;
  }

  .sc-user-input--file-icon path,
  .sc-user-input--button-icon-wrapper path {
    fill: white !important;
    opacity: .8;
  }

  .sc-message {
    width: 100%;
    padding-left: 1em;
    padding-right: 1.2em;

    &--meta {
      font-size: .68rem !important;
    }

    &--edited {
      display: none;
    }

    &--text-content {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    &--content.received {

      .sc-message--meta,
      .sc-message--text,
      .sc-message--file,
      .sc-message--toolbox,
      .sc-message--file-text,
      .sc-message--toolbox svg {
        fill: $secondary !important;
        color: $secondary !important;
        background-color: white !important;

        /*
        button > svg {
          color: $font-color;
        }
        */

        button[disabled=disabled] > svg {
          color: $negative;
        }
      }

      .sc-message--meta {
        color: inherit !important;
      }
    }

    &--content.sent {

      .sc-message--meta,
      .sc-message--text,
      .sc-message--file,
      .sc-message--toolbox,
      .sc-message--file-text {
        color: white !important;
        background-color: lighten($primary, 2) !important;

        button[disabled=disabled] > svg {
          color: $negative;
        }
      }

      .sc-message--meta {
        color: inherit !important;
      }
    }

    &--system {
      text-align: center;
      background-color: lighten($primary, 40) !important;
    }
  }
}
