@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.fr-view,
div.fr-view,
.fr-box.fr-basic .fr-element.fr-view {
  color: inherit;
  text-align: inherit;
  font-size: 1.066rem;
  font-family: $typography-font-family;

  a {
    color: $link-color;
    text-decoration: underline;
  }

  hr {
    height: 2px;
    border: none;
    background-color: $secondary;
  }
  blockquote {
    padding-left: 15px;
    color: darken($primary, 10);
    border-left: solid 2px $primary;
  }

  & > p:last-child {
    margin-bottom: 0 !important;
  }
}
