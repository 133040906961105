@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

// Global.

html,
body {
  color: $font-color;
  font-size: $font-size;
}

body {
  overflow: overlay;
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

body::-webkit-scrollbar {
  display: none;
}

input,
textarea {
  font-size: 1.066rem;
}

a {
  color: $primary;
  text-decoration: none;
}

p {
  margin-bottom: $ibs-y;
}

.full-basis {
  flex-basis: 100%;
}

.clearfix:after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}

// Radius.

.card-radius {
  border-radius: $card-radius !important;
}

.generic-radius {
  border-radius: $generic-border-radius !important;
}

// Shadow.

.card-shadow {
  box-shadow: $card-shadow;
}

.card-shadow_hover {
  transition: box-shadow .2s linear;
}

.card-shadow_hover:hover {
  box-shadow: $card-shadow;
}

// Text.

.text-small {
  font-size: .95rem;
  line-height: 1.2rem;
}

.text-default {
  color: $font-color;
}

.text-underline {
  text-decoration: underline;
}

// Color.

.bg-light {
  background-color: $light;
}

.text-light {
  color: $light;
}

.text-light-accent {
  color: $light-accent;
}

.bg-light-accent {
  background-color: $light-accent;
}

// Helpers.

.no-shrink {
  flex-shrink: 0 !important;
}

.no-min-width {
  min-width: 0 !important;
}

.no-visibility {
  visibility: hidden;
}

.max-width-100 {
  max-width: 100%;
}

.max-height-100 {
  max-width: 100%;
}

.hide-first:first-child {
  display: none;
}

.hide-last:last-child {
  display: none;
}

// Alpha Pattern.

.alpha-pattern {
  background-size: $alpha-pattern-size;
  background-image: $alpha-pattern-image;
  background-color: $alpha-pattern-color;
}

// Brand Gradient.

.bg-brand-gradient {
  background: $brand-gradient;
}

// Art Btn.

.art-btn.q-btn,
.art-btn_left.q-btn,
.art-btn_right.q-btn {

  .q-icon {
    font-size: 3.7em;
    margin-top: -.08em;
  }
}

.art-btn_left.q-btn {
  margin-left: 1.48em;

  .q-icon {
    margin-right: .4em;
  }
}

.art-btn_right.q-btn {
  margin-right: 1.48em;

  .q-icon {
    margin-left: .4em;
  }
}

// Art Pagination.

.art-pagination.q-pagination {

  .q-btn {
    border-radius: 50%;
    padding: 0 16px !important;
    min-width: 2.7em !important;
    min-height: 2.7em !important;
  }

  .q-btn.text-white {
    color: $primary !important;
  }

  .q-btn.bg-primary {
    background-color: white !important;
  }

  .q-btn.bg-primary:before {
    top: 50%;
    left: 50%;
    content: '';
    width: 1.7em;
    height: 1.7em;
    margin-top: -.8em;
    position: absolute;
    border-radius: 50%;
    margin-left: -.7em;
    background-color: $accent;
  }

  .q-btn .q-btn__content {
    position: relative;
  }
}

// Block Btn.

.block-btn.q-btn {
  font-weight: 500;
  font-size: 1.1rem;
  padding: ($space-base * .08) ($space-base * .6);

  & .q-btn__wrapper {
    min-height: 2.3em;
  }

  &.q-btn--outline .q-btn__wrapper:before {
    border-width: 3px;
  }

  &.q-btn__thin-border .q-btn__wrapper:before {
    border-width: 2px;
  }
}

// Page Header Offset.

.page-header-offset {
  padding-top: $page-header-height !important;

  @media (max-width: $breakpoint-sm) {
    padding-top: $page-header-height-sm !important;
  }

  @media (max-width: $breakpoint-xs) {
    padding-top: $page-header-height-xs !important;
  }
}

// Scroll.

.scroll_grey {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: $scroll-weight;
    height: $scroll-weight * 2;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(155, 155, 155, .2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(200, 200, 200, .8);
  }
}
