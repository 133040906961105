.sc-chat-window {
  border-radius: .4rem;
  width: 100% !important;
  background-color: transparent !important; }
  .sc-chat-window .sc-message-list {
    background-color: rgba(150, 150, 150, 0.2) !important; }
  .sc-chat-window .sc-user-input {
    background-color: rgba(150, 150, 150, 0.2) !important; }
  .sc-chat-window .sc-user-input--text {
    width: 100%;
    color: white !important; }
  .sc-chat-window .sc-user-input--file-icon-wrapper {
    display: flex; }
  .sc-chat-window .sc-user-input--file-icon path,
  .sc-chat-window .sc-user-input--button-icon-wrapper path {
    fill: white !important;
    opacity: .8; }
  .sc-chat-window .sc-message {
    width: 100%;
    padding-left: 1em;
    padding-right: 1.2em; }
    .sc-chat-window .sc-message--meta {
      font-size: .68rem !important; }
    .sc-chat-window .sc-message--edited {
      display: none; }
    .sc-chat-window .sc-message--text-content {
      margin-top: 1em;
      margin-bottom: 1em; }
    .sc-chat-window .sc-message--content.received .sc-message--meta,
    .sc-chat-window .sc-message--content.received .sc-message--text,
    .sc-chat-window .sc-message--content.received .sc-message--file,
    .sc-chat-window .sc-message--content.received .sc-message--toolbox,
    .sc-chat-window .sc-message--content.received .sc-message--file-text,
    .sc-chat-window .sc-message--content.received .sc-message--toolbox svg {
      fill: #4C4477 !important;
      color: #4C4477 !important;
      background-color: white !important;
      /*
        button > svg {
          color: $font-color;
        }
        */ }
      .sc-chat-window .sc-message--content.received .sc-message--meta button[disabled=disabled] > svg,
      .sc-chat-window .sc-message--content.received .sc-message--text button[disabled=disabled] > svg,
      .sc-chat-window .sc-message--content.received .sc-message--file button[disabled=disabled] > svg,
      .sc-chat-window .sc-message--content.received .sc-message--toolbox button[disabled=disabled] > svg,
      .sc-chat-window .sc-message--content.received .sc-message--file-text button[disabled=disabled] > svg,
      .sc-chat-window .sc-message--content.received .sc-message--toolbox svg button[disabled=disabled] > svg {
        color: #E5083B; }
    .sc-chat-window .sc-message--content.received .sc-message--meta {
      color: inherit !important; }
    .sc-chat-window .sc-message--content.sent .sc-message--meta,
    .sc-chat-window .sc-message--content.sent .sc-message--text,
    .sc-chat-window .sc-message--content.sent .sc-message--file,
    .sc-chat-window .sc-message--content.sent .sc-message--toolbox,
    .sc-chat-window .sc-message--content.sent .sc-message--file-text {
      color: white !important;
      background-color: #232323 !important; }
      .sc-chat-window .sc-message--content.sent .sc-message--meta button[disabled=disabled] > svg,
      .sc-chat-window .sc-message--content.sent .sc-message--text button[disabled=disabled] > svg,
      .sc-chat-window .sc-message--content.sent .sc-message--file button[disabled=disabled] > svg,
      .sc-chat-window .sc-message--content.sent .sc-message--toolbox button[disabled=disabled] > svg,
      .sc-chat-window .sc-message--content.sent .sc-message--file-text button[disabled=disabled] > svg {
        color: #E5083B; }
    .sc-chat-window .sc-message--content.sent .sc-message--meta {
      color: inherit !important; }
    .sc-chat-window .sc-message--system {
      text-align: center;
      background-color: #848484 !important; }
